import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj
} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'

import Container from '../components/container'
import PortableText from '../components/portableText'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import AccessesArchive from '../components/accesses-archive'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AccessPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    accesses: allSanityAccess(
      limit: 100
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            _type
            _id
            publishedAt
            slug{
              current
            }
            title
            subTitle
            mainImage{
              ...SanityImage
              alt
            }
            address
            googleMaps
            tel
            fax
          }
        }
      }
  }

`

const AccessPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const accessesNodes = (data || {}).accesses
    ? mapEdgesToNodes(data.accesses)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title='アクセス'
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        {accessesNodes && (
          <AccessesArchive
            nodes={accessesNodes}
          />
        )}
      </Container>
    </Layout>
  )
}

export default AccessPage
