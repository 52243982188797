import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'
import {elementType} from 'prop-types'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  margin: 0 1.6rem;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
 `

const Access = styled.div`
  margin: 0 0 8rem 0;
`

const Text = styled.p`
  margin-bottom: 5.6rem;
`

const Title = styled.h2`
  font-size: 1.8rem;
  font-family: ${fontfamily.jaRounded};
  display: block;
`

const SubTitle = styled.span`
  font-size: 1.4rem;
  font-family: ${fontfamily.jaRounded};
  color: ${colors.green};
  margin: 0 0 1.6rem 0 ;
  display: block;
`

const Label = styled.span`
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  line-height: 1;
`

const Item = styled.div`
  display: block;
  font-size: 1.6rem;
  margin: 0 0 2.4rem 0;
`

const TextBr = styled.p`
  white-space: pre-line;
  margin: 0;
`

const GoogleMaps = styled.a`
  font-size: 1.4rem;
`
const StyledLink = styled(props => <Link {...props} />)`
`

function AccessesArchive (props) {
  return (
    <Wrapper>
      <Heading title='アクセス' titleEn='Access' />
      <Text>
        お電話でのご連絡は該当の校舎にて承っております。<br />
        オンラインでのお問い合わせは、<StyledLink to='/contact/'>お問い合わせページ</StyledLink>のフォームよりお問い合わせください。
      </Text>
      {props.nodes &&
          props.nodes.map(node => (
            <Access>
              <Title>{node.title}</Title>
              <SubTitle>{node.subTitle}</SubTitle>
              <Item>
                <Label>住所</Label>
                <TextBr>{node.address}</TextBr>
                <GoogleMaps href={node.googleMaps}>Google Mapsで開く</GoogleMaps>
              </Item>
              <Item>
                <Label>電話</Label>
                <TextBr>{node.tel}</TextBr>
              </Item>
              {node.fax &&
              <Item>
                <Label>Fax</Label>
                <TextBr>{node.fax}</TextBr>
              </Item>
              }
            </Access>
          ))}
    </Wrapper>
  )
}

AccessesArchive.defaultProps = {
  title: '',
  titleEn: '',
  nodes: []
}

export default AccessesArchive
